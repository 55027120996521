<template>
  <SubletPage :title="title">
    <CollapseCard
      is-primary
      initially-open
      class="mb-4"
      :closed-identifier="dynamicClosedIdentifier"
    >
      <h3 slot="title-text">Läs detta först</h3>
      <template slot="body-text">
        <div class="info-text-container">
          <p>
            För att säkerställa att rätt förutsättningar finns för att göra en
            överlåtelse kräver hyresvärden underlag från dig. Dessa
            underlagsdokument ska styrka den anledning du angett i steget innan.
          </p>
          <template v-for="groupKey in documentGroupsKeys">
            <div
              v-for="(text, index) in getDocumentInfoTexts(groupKey)"
              :key="`${groupKey}-${index}`"
            >
              <h3>
                {{ text.title }}
              </h3>
              <p v-html="text.content" />
            </div>
          </template>
        </div>
        <br />
        <p>Tänk på att:</p>
        <ul>
          <li>Alla fyra hörn skall vara synliga</li>
          <li>Kvaliteten skall vara god även vid utskrift</li>
          <li>
            Filerna måste vara i något av följande format, .pdf / .jpg / .png
          </li>
        </ul>
        <p>
          <strong>Tips:</strong> För att fota dokument på bästa sätt
          rekommenderar vi att du använder dig av en app som scannar av
          dokument. Exempel på appar som fungerar bra till både iOS och Android
          är Adobe Scan och Genius Scan, båda helt gratis att använda.
        </p>
        <ul>
          <li>
            <a
              href="https://apps.apple.com/se/app/adobe-scan-document-scanner/id1199564834"
              target="_blank"
              >Länk till Adobe Scan iOS</a
            >
          </li>
          <li>
            <a
              href="https://apps.apple.com/se/app/genius-scan-pdf-scanner/id377672876"
              target="_blank"
              >Länk till Genius Scan iOS</a
            >
          </li>
          <li>
            <a
              href="https://play.google.com/store/apps/details?id=com.adobe.scan.android&hl=sv"
              target="_blank"
              >Länk till Scan Android</a
            >
          </li>
          <li>
            <a
              href="https://play.google.com/store/apps/details?id=com.thegrizzlylabs.geniusscan.free&hl=sv"
              target="_blank"
              >Länk till Genius Scan Android</a
            >
          </li>
        </ul>
        <p>
          Behöver du hjälp? Hör av dig till oss på
          <a
            :href="`mailto:${getSupportAddress}?subject=Andra%20Hand`"
            target="_blank"
          >
            {{ getSupportAddress }}</a
          >
        </p>
      </template>
    </CollapseCard>
    <Summary
      v-if="!applicationIsEditable && !documentsRejected"
      category="DOCUMENTS"
      class="documents-view-wrapper"
      :show-change-btn="false"
    >
      <SummaryBody sub-heading="Uppladdade filer" class="documents-subheading">
        <SubletDocumentsGrid
          v-if="documentsForSelectedReason.length > 0"
          :documents="documentsForSelectedReason"
          :application-id="application.id"
        />
      </SummaryBody>
    </Summary>
    <div v-else class="upload-area">
      <p>* <i>Obligatoriskt att ladda upp</i></p>
      <OvDocumentsUpload
        v-for="{ category, required } in activeCategoriesForUser"
        :key="category"
        :label="getLabel(category, required)"
        :description="getDescription(category)"
        :document-category="category"
        :category-type="categoryType"
        :documents="getFilteredDocuments(category)"
        :computed-documents-groups="computedDocumentsGroups"
      />
    </div>
    <BaseButton
      class="go-to-next-category-btn"
      type="button"
      :is-rounded="true"
      :is-fullwidth="isMobile"
      :is-disabled="!applicationIsEditable && !isMobile"
      :is-small="isMobile"
      @click="goToNextCategory($router)"
      ><BaseIconText :icon-url="svgs.ICONS.ARROW_RIGHT" :align-right="true">
        Gå vidare
      </BaseIconText>
    </BaseButton>
  </SubletPage>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import SubletPage from '@/components/SubletPage.vue';
import OvDocumentsUpload from '@/components/OvDocumentsUpload.vue';
import CollapseCard from '@/components/CollapseCard.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseIconText from '@/components/BaseIconText.vue';
import Summary from '@/components/summary/Summary.vue';
import SummaryBody from '@/components/summary/SummaryBody.vue';
import icons from '@/utils/icon-constants';
import svgs from '@/utils/icon-constants';
import SubletDocumentsGrid from '@/components/SubletDocumentsGrid.vue';

export default {
  name: 'OvDocuments',
  components: {
    SubletPage,
    OvDocumentsUpload,
    CollapseCard,
    BaseButton,
    BaseIconText,
    Summary,
    SummaryBody,
    SubletDocumentsGrid
  },
  props: {
    title: {
      type: String,
      required: true
    },
    computedDocumentsGroups: {
      type: Object,
      required: true
    },
    categoryType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      icons,
      svgs
    };
  },
  computed: {
    ...mapState({
      application: state => state.ovApplication.application
    }),
    ...mapGetters({
      getSupportAddress: 'app/getSupportAddress',
      isMobile: 'app/isMobile',
      applicationIsEditable: 'ovApplication/applicationIsEditable',
      rejectedCategories: 'ovApplication/rejectedCategories',
      requiredDocuments: 'ovApplication/requiredDocuments',
      requiredInviteeDocuments: 'ovApplication/requiredInviteeDocuments',
      noneRequiredDocuments: 'ovApplication/noneRequiredDocuments',
      noneInviteeRequiredDocuments:
        'ovApplication/noneInviteeRequiredDocuments',
      isPrivateFlow: 'ovApplication/isPrivateFlow'
    }),
    documentGroupsKeys() {
      if (!this.computedDocumentsGroups) {
        return [];
      }
      return Object.keys(this.computedDocumentsGroups);
    },
    documentsRejected() {
      return (
        this.rejectedCategories.includes('documents') ||
        this.rejectedCategories.includes('computedDocumentsGroups') ||
        this.rejectedCategories.includes('inviteeComputedDocumentsGroups')
      );
    },
    categories() {
      const categories = Object.keys(this.computedDocumentsGroups).map(key => ({
        rejectReason: this.computedDocumentsGroups[key].rejectReason,
        status: this.computedDocumentsGroups[key].status,
        category: key
      }));
      return [...this.documents, ...categories];
    },
    documents() {
      return this.application?.documents || [];
    },
    reason() {
      return this.application?.reason;
    },
    reasonType() {
      return this.application?.reason?.type;
    },
    housingType() {
      return this.application?.reason?.housingType;
    },
    dynamicClosedIdentifier() {
      return `default_info_card_${this.reasonType}`;
    },
    getDocumentsPerCategory() {
      if (!this.reason || !this.reason.type) {
        return [];
      }

      if (this.categoryType === 'transferee') {
        return [
          ...this.requiredInviteeDocuments,
          ...this.noneInviteeRequiredDocuments
        ];
      }
      return [...this.requiredDocuments, ...this.noneRequiredDocuments];
    },
    documentsForSelectedReason() {
      const documentsToShow = this.getDocumentsPerCategory;
      const filteredDocuments = this.documents.filter(
        doc =>
          documentsToShow.includes(doc.category) &&
          doc.categoryType === this.categoryType
      );

      return filteredDocuments;
    },
    activeCategoriesForUser() {
      if (!Object.keys(this.computedDocumentsGroups).length) {
        return null;
      }
      return Object.entries(this.computedDocumentsGroups)
        .map(([key, props]) => ({
          category: key,
          required: props.required
        }))
        .sort((a, b) => {
          if (b.required !== a.required) {
            return b.required - a.required;
          }

          return a.category.localeCompare(b.category);
        });
    }
  },
  methods: {
    ...mapActions({
      goToNextCategory: 'ovApplication/goToNextCategory'
    }),
    getDocumentInfoTexts(key) {
      switch (key) {
        case 'uc':
          return [
            {
              title: 'Hur laddar jag upp UC?',
              content: `Du laddar enkelt ned en kreditupplysning via tjänsten <a href="http://www.minuc.se/min-upplysning/" target="_blank">Min upplysning</a> för 39kr/person. 
            Observera att kreditupplysningen måste inhämtas personligen från MinUC. 
            Inga andra kreditupplysningar godkänns.`
            }
          ];
        case 'familjebevis':
          return [
            {
              title:
                'Vad är ett familjebevis och varför måste jag ladda upp ett sådant?',
              content: `Ett familjebevis är en viss typ av personbevis som inte bara visar folkbokföringsadress utan även eventuella barn och andra familjeförhållanden. Familjebeviset skickas till din folkbokföringsadress via e-post och går ej att hämta online. Körkort, ID-kort samt pass gäller ej som personbevis. Beställning av familjebevis gör du enklast <a href="https://skatteverket.se/privat/folkbokforing/bestallpersonbevis/allapersonbevis.4.361dc8c15312eff6fdb20.html?mode=list&code=1209" target="_blank">här</a> (länk till Skatteverket).`
            },
            {
              title: 'För vem ska jag ladda upp familjebevis?',
              content: `Du behöver ladda upp familjebevis för nuvarande hyresgäst och föreslagen hyresgäst. Tänk på att familjebeviset får ej vara äldre än 3 månader.`
            }
          ];
        case 'dodsfallsbevis':
          return [
            {
              title: 'Var beställer jag dödsfallsbevis?',
              content: `Dödsfallsbevis kan du 
              <a href="https://skatteverket.se/privat/folkbokforing/narenanhorigdor/dodsfallsintygmedslaktutredning.4.233f91f71260075abe8800017118.html" target="_blank">beställa här hos skatteverket</a>.`
            }
          ];
        case 'fullmakt':
          return [
            {
              title: 'Vad ska fullmakten innehålla?',
              content: `Fullmakten ska innehålla den avlidnes namn och personnummer, samt namn och personnummer på 
              den person som får fullmakt att företräda dödsboet. Den ska vara undertecknad av samtliga dödsbodelägare 
              och det ska framgå vilken ort och vilket datum fullmakten har upprättats. Fullmakten ska skickas in i original 
              eller som en vidimerad kopia.`
            }
          ];
        case 'inkomstunderlag':
          return [
            {
              title: 'Vad är ett intyg om inkomst?',
              content: `Ett intyg för inkomst kan exempelvis vara en lönespecifikation, föräldraledighet / Försäkringskassan, 
              A-kassa, CSN, pension m.m. gäller också som inkomst och då bifogas liknande underlag i form av utbetalningar, 
              beslut m.m.`
            },
            {
              title: 'Inkomstdeklaration gäller ej som inkomstunderlag.',
              content: `Som egenföretagare (Enskild firma) behövs balans- samt resultat-rapport. Som egenföretagare (Aktiebolag) 
              gäller samma som för Enskild firma och i de fall det finns en revisor bifogas med fördel även ett intyg på 
              löneutbetalningar från denne. Besparingar räknas inte som löpande inkomst.`
            }
          ];
        case 'aldreboendeintyg':
          return [
            {
              title: 'Vad ska intyget om flytt till äldreboende innehålla?',
              content: `Intyget ska innehålla namn och personnummer, namnet på det äldreboende du har blivit beviljad 
              plats på, samt en bekräftelse på att du har tackat ja till platsen. Det ska också framgå vilket datum du 
              förväntas flytta in. Intyget ska vara utfärdat av en ansvarig handläggare eller motsvarande person från 
              kommunen eller det aktuella boendet. Informationen behövs för att vi ska kunna bedöma din ansökan om överlåtelse 
              enligt gällande regler.`
            }
          ];
        case 'separationsintyg':
          return [
            {
              title: 'Hur ska jag intyga min separation eller skilsmässa?',
              content: `För att styrka att en separation eller skilsmässa har skett behöver du skicka in dokumentation som 
              visar att ni inte längre bor tillsammans som ett par. Det kan till exempel vara:
              <ul>
                <li>Ett personbevis där det framgår att ni är folkbokförda på olika adresser</li>
                <li>En kopia på ansökan om skilsmässa eller beslut om äktenskapsskillnad från tingsrätten</li>
                <li>Ett intyg från socialtjänst eller annan myndighet som bekräftar separationen</li>
                <li>Ett undertecknat avtal om bodelning eller annan handling som visar att samboförhållandet upphört</li>
              </ul>
              `
            }
          ];
        case 'lakarintyg':
          return [
            {
              title: 'Vad ska läkarintyget innehålla?',
              content: `Om du ansöker om att överlåta din lägenhet på grund av hälsoskäl behöver läkarintyget styrka 
              att din hälsosituation gör det svårt eller omöjligt för dig att bo kvar i lägenheten. Intyget ska vara 
              utfärdat av en legitimerad läkare. Observera att intyget inte behöver innehålla detaljerad medicinsk 
              journalinformation, men det ska ge tillräcklig information för att vi ska kunna göra en bedömning av ditt ärende.`
            }
          ];
        case 'csn':
          return [
            {
              title: 'Varför måste jag ladda upp ett intyg från CSN?',
              content: `För att säkerställa att skälet du valt är giltigt.`
            },
            {
              title: 'Jag kommer inte att ansöka om CSN, vad gör jag då?',
              content: `Om du väljer att inte ansöka om CSN (Centrala Studiestödsnämnden) för finansiellt stöd under dina studier,
               måste du istället tillhandahålla andra dokument som antagningsbevis för att styrka din antagning till den skola 
               eller institut där du planerar att genomföra dina studier.`
            }
          ];
        case 'adressbevis':
          return [
            {
              title: 'Varför behövs ett bevis på min nya bostad?',
              content: `När du ansöker om att överlåta din hyresrätt behövs ett bevis på din nya bostad för att kunna 
              säkerställa att du faktiskt har för avsikt att flytta därifrån permanent. Överlåtelse av hyresrätt är bara 
              möjlig om du själv inte längre ska bo kvar i lägenheten, och ett intyg eller avtal för din nya bostad är ett 
              sätt för oss att kontrollera detta. `
            }
          ];
        case 'studier':
          return [
            {
              title: 'Vad är ett bevis på avslutade studier?',
              content: `Ett bevis på avslutade studier är ett dokument som visar att du har fullföljt din utbildning. Det kan
               till exempel vara ett examensbevis, ett examensintyg eller ett studieintyg från skolan där det framgår att du 
               inte längre är registrerad som studerande. Intyget ska innehålla ditt namn, personnummer (eller födelsedatum), 
               vilken utbildning det gäller samt datum för när studierna avslutades. Dokumentet ska vara utfärdat av skolan 
               eller utbildningsanordnaren.`
            }
          ];
        default:
          return [{ title: '', content: '' }];
      }
    },
    getFilteredDocuments(category) {
      if (!category || this.documents.length === 0) {
        return [];
      }

      const currDocumentCat = this.documents.filter(
        document =>
          document.category === category &&
          document.categoryType === this.categoryType
      );

      return currDocumentCat;
    },
    getLabel(category, required) {
      const reqSymbol = required ? '*' : '';
      let text = '';

      switch (category) {
        case 'csn-intyg':
          text = 'Här laddar du upp intyg från CSN';
          break;
        case 'familjebevis':
          text = 'Här laddar du upp ditt familjebevis';
          break;
        case 'fullmakt':
          text = 'Här laddar du upp fullmakt';
          break;
        case 'övrigt':
          text = this.isPrivateFlow
            ? 'Här laddar du upp övriga dokument som styrker din anledning'
            : 'Här laddar du upp ev. övriga dokument';
          break;
        case 'anställningsbevis':
          text = 'Här laddar du upp anställningsbevis';
          break;
        case 'kontrakt':
          text = 'Här laddar du upp ditt nuvarande hyresavtal';
          break;
        case 'uc':
          text = 'Här laddar du upp din UC';
          break;
        case 'köpekontrakt':
          text = 'Här laddar du upp köpekontrakt';
          break;
        case 'dodsfallsintyg':
          text = 'Här laddar du upp dödsfallsintyg';
          break;
        case 'adressbevis':
          text = 'Här laddar du upp intyg om utflyttning';
          break;
        case 'lakarintyg':
          text = 'Här laddar du upp läkarintyg som styrker hälsoproblemen';
          break;
        case 'separationsintyg':
          text = 'Här laddar du upp intyg om separation eller skilsmässa';
          break;
        case 'inkomstunderlag':
          text = 'Här laddar du upp intyg om inkomst';
          break;
        case 'aldreboendeintyg':
          text =
            'Här laddar du upp intyg från kommunen om plats på äldreboende eller liknande';
          break;
        case 'studier':
          text = 'Här laddar du upp intyg om avslutade studier';
          break;
        case 'antagningsbesked':
          text =
            'Här laddar du upp intyg om antagningsbesked eller registreringsintyg';
          break;
        case 'arsredovisning':
          text = 'Här laddar du upp er årsredovisning';
          break;
        case 'hyresavtal':
          text = 'Här laddar du ert hyresavtal';
          break;
        case 'registreringsbevis':
          text = 'Här laddar du upp ert registreringsbevis';
          break;
        case 'affarsplan':
          text = 'Här laddar du upp er affärsplan';
          break;
        default:
          text = category;
      }

      text = `${text}${reqSymbol}`;
      return text;
    },
    getDescription(category) {
      switch (category) {
        case 'familjebevis':
          return 'Även på den personen du ska provbo med';
        case 'csn-intyg':
          return 'Kommer du inte att ansöka om CSN? Då kan du istället ladda upp ditt antagningsbevis.';
        case 'anställningsbevis':
          return 'Anställningsavtalet behöver styrka den nya orten som du ska arbeta på.';
        case 'köpekontrakt':
          return 'Här laddar du upp köpekontrakt där det framgår vem som äger villan dit ni ska flytta.';
        default:
          return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  &:hover {
    color: var(--color-blue);
  }
}
.info-text-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .q-and-a-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
  }
}

.upload-area > * {
  margin-bottom: 3.2rem;
  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.go-to-next-category-btn {
  margin-top: 4.8rem;
}

.documents-subheading {
  @include mobile {
    display: flex;
    align-items: center !important;
    width: 100%;
  }
}
</style>
